<template>
  <v-card width="456" height="616" class="px-3 px-md-6 py-6">
    <v-form ref="form" v-model="valid" @submit.prevent="submit">
      <v-text-field
        name="currentPassword"
        v-model="changePasswordModel.currentPassword"
        :rules="passwordRules"
        required
        :type="showPassword ? 'text' : 'password'"
        :label="
          $t('panel.settingsPage.changePasswordComponent.currentPassword')
        "
        filled
        hide-details="auto"
        class="theme-custom-input mb-4"
      >
        <template v-slot:append>
          <div @click="showPassword = !showPassword" class="cursor-pointer">
            <v-icon size="24" v-if="showPassword">$eyeShow</v-icon>
            <v-icon size="24" v-else>$eyeHidden</v-icon>
          </div>
        </template>
      </v-text-field>

      <v-text-field
        name="newPassword"
        v-model="changePasswordModel.newPassword"
        @input="validatePassword"
        @focus="reInitValidation"
        @blur="reInitValidation"
        :rules="newPasswordRules"
        required
        :type="showNewPassword ? 'text' : 'password'"
        :label="$t('panel.settingsPage.changePasswordComponent.newPassword')"
        filled
        hide-details="auto"
        class="theme-custom-input mb-4"
        :class="{ 'mb-2': isFocused || isBlurred }"
      >
        <template v-slot:append>
          <div
            @click="showNewPassword = !showNewPassword"
            class="cursor-pointer"
          >
            <v-icon size="24" v-if="showNewPassword">$eyeShow</v-icon>
            <v-icon size="24" v-else>$eyeHidden</v-icon>
          </div>
        </template>
      </v-text-field>

      <div v-if="isFocused || (passwordNotValid && isBlurred)" class="mb-5">
        <div class="font-regular-12 gray12--text mb-1">
          {{
            $t("panel.settingsPage.changePasswordComponent.passwordRegexTitle")
          }}
        </div>

        <ul class="custom-list">
          <li v-for="item in regexErrors" :key="item.type">
            <v-badge
              :color="item.color"
              dot
              inline
              class="badge-size"
            ></v-badge>

            <span class="font-regular-10 gray9--text">{{ item.message }}</span>
          </li>
        </ul>
      </div>

      <v-text-field
        name="confirmNewPassword"
        v-model="changePasswordModel.confirmNewPassword"
        :rules="passwordConfirmationRules"
        required
        :type="showPasswordConfirmation ? 'text' : 'password'"
        :label="
          $t('panel.settingsPage.changePasswordComponent.confirmNewPassword')
        "
        filled
        hide-details="auto"
        class="theme-custom-input mb-4"
      >
        <template v-slot:append>
          <div
            @click="showPasswordConfirmation = !showPasswordConfirmation"
            class="cursor-pointer"
          >
            <v-icon size="24" v-if="showPasswordConfirmation">$eyeShow</v-icon>
            <v-icon size="24" v-else>$eyeHidden</v-icon>
          </div>
        </template>
      </v-text-field>

      <v-btn
        :disabled="!valid"
        color="primary"
        depressed
        width="132"
        height="50"
        :loading="loading"
        type="submit"
      >
        <span class="font-semiBold-14">
          {{ $t("panel.settingsPage.changePasswordComponent.confirm") }}
        </span>
      </v-btn>
    </v-form>
  </v-card>
</template>

<script>
import { AuthService, UserInfoService } from "@/services";

export default {
  name: "change-password",
  components: {},
  props: [],
  data() {
    return {
      validInput: true,
      loading: false,
      valid: false,
      showPassword: false,
      showNewPassword: false,
      showPasswordConfirmation: false,
      changePasswordModel: {
        currentPassword: "",
        newPassword: "",
        confirmNewPassword: "",
      },
      passwordRules: [
        (v) =>
          !!v ||
          this.$t(
            "panel.settingsPage.changePasswordComponent.currentPasswordNotEmpty"
          ),
      ],
      newPasswordRules: [
        (v) =>
          !!v ||
          this.$t(
            "panel.settingsPage.changePasswordComponent.newPasswordNotEmpty"
          ),
        (v) =>
          (v && this.validInput) ||
          this.$t("panel.settingsPage.changePasswordComponent.invalidPassword"),
      ],
      validationRegex: [],
      matchedCase: [],
      validationMessage: "",
      regexErrors: [],
      isFocused: false,
      isBlurred: false,
    };
  },
  computed: {
    passwordConfirmationRules() {
      const rules = [];

      if (!this.changePasswordModel.confirmNewPassword) {
        const required = (v) =>
          !!v ||
          this.$t(
            "panel.settingsPage.changePasswordComponent.confirmPasswordNotEmpty"
          );

        rules.push(required);
      }

      if (this.changePasswordModel.newPassword) {
        const match = (v) =>
          (!!v && v) === this.changePasswordModel.newPassword ||
          this.$t(
            "panel.settingsPage.changePasswordComponent.passwordMustMatch"
          );

        rules.push(match);
      }

      return rules;
    },
    passwordNotValid() {
      return this.matchedCase.find((elem) => !elem.isValid);
    },
  },
  watch: {
    "changePasswordModel.newPassword": "validateFields",
  },
  mounted() {
    this.getPasswordRegex();
  },
  methods: {
    validateFields() {
      this.$refs.form.validate();
    },
    getPasswordRegex() {
      AuthService.passwordRegex()
        .then((res) => {
          this.validationRegex = res.data.data;
        })
        .then(() => {
          this.validationRegex.forEach((elem) => {
            let regex = elem.regex;
            regex = regex.substring(1, regex.length - 1);
            this.matchedCase.push({
              message: elem.message,
              type: elem.type,
              regex: regex,
              color: "gray9",
              isValid: false,
            });
          });
        });
    },
    validatePassword(value) {
      this.regexErrors = [];
      this.validInput = true;
      this.matchedCase.forEach((elem) => {
        if (value.match(elem.regex)) {
          if (this.isFocused) {
            elem.color = "success";
            elem.isValid = true;
          }
          this.regexErrors.push(elem);
        } else {
          if (this.isFocused) {
            elem.color = "gray9";
            elem.isValid = false;
            this.validInput = false;
          }
          this.regexErrors.push(elem);
        }
      });
    },
    reInitValidation(event) {
      if (event.type === "focus") {
        this.isFocused = true;
        this.regexErrors = [...this.matchedCase];
      } else if (event.type === "blur") {
        this.isBlurred = true;
        this.isFocused = false;
        this.validatePassword(this.changePasswordModel.newPassword);
      }
    },
    submit() {
      this.loading = true;
      UserInfoService.changePassword(this.changePasswordModel)
        .then((res) => {
          this.$toast.success(res.data.message, {
            icon: this.$vuetify.icons.values.checkMarkDone,
          });
          this.$router.replace("/auth");
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
@import "ChangePassword";
</style>
